import React from 'react'
import logo from '../../logo.svg'
export const Header = () => {
    return (
        <div class="bg-black">
            <header class="absolute inset-x-0 top-0 z-50">
                <nav class="flex justify-center  items-center sm:justify-between  p-6 lg:px-8 " aria-label="Global">
                    <div class="flex lg:flex-1">
                        <a href="#" class="-m-1.5 p-1.5">
                            <span class="sr-only">TheGrootpk</span>
                            <img class="h-8 w-auto" src={logo} alt="TheGrootpk" style={{width:"200px",height:"80px"}} />
                        </a>
                    </div>
        
          
                    <div class="hidden lg:flex lg:flex-1 lg:justify-end">
                        <a href="https://www.instagram.com/thegrootpk/" class="text-sm font-semibold leading-6 text-gray-100">Instagram <span aria-hidden="true">&rarr;</span></a>
                    </div>
                </nav>
            </header>
            <div class="relative isolate px-6 pt-14 lg:px-8">
                <div class="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80" aria-hidden="true">
                    <div class="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
                <div class="mx-auto max-w-2xl py-32 sm:py-36 lg:py-40">
                    <div class="text-center">
                        <h1 class="text-2xl font-bold tracking-tight text-gray-100 sm:text-5xl">Delicious fast food for when you're hungry. Come and enjoy our tasty treats! 🍔🍟🌯🥪 #YummyFood</h1>
                        <div class="mt-10 flex items-center justify-center gap-x-6">
                            <a href="https://www.foodbooking.com/api/fb/_d_gy9_d" target='_blank' rel='nofollow' class="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Order Now</a>
                        </div>
                    </div>
                </div>
                <div class="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]" aria-hidden="true">
                    <div class="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]" style={{ clipPath: "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)" }}></div>
                </div>
            </div>
        </div>
    )
}

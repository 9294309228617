import React from 'react'

export const Location = () => {
    return (

        <div class="bg-black flex items-center justify-center w-full ">
        <div class=" ">
       
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27202.357212473496!2d74.44589634257468!3d31.543527279940104!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190fa783fbb95b%3A0xcf12fcacf90cc0e!2sThe%20Groot!5e0!3m2!1sen!2s!4v1703612208097!5m2!1sen!2s" width="600" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      
        </div>
    </div>

    )
}


import './App.css';
import { Header } from './components/Header/Header';
import { Location } from './components/Location/Location';

function App() {
  return (
    <div className='wrapper'>
      <Header />
      <Location />
    </div>
  );
}

export default App;
